<script setup>
    import PrimevueMenubar from 'primevue/menubar';

    const viewport = useViewport();
</script>

<template>
    <PrimevueMenubar breakpoint="1023px" :class="{'p-menubar-mobile': ! viewport.isGreaterOrEquals('lg')}">
        <template v-if="$slots.start" #start>
            <slot name="start" />
        </template>

        <template v-if="$slots.end" #end>
            <slot name="end" />
        </template>

        <template v-if="$slots.item" #item="{item}">
            <slot name="item" :item="item" />
        </template>

        <template #itemicon="{item}">
            <slot v-if="$slots.itemicon" name="itemicon" :item="item" />
            <Icon v-else-if="item.icon" :name="item.icon" class="mr-1" />
        </template>
    </PrimevueMenubar>
</template>
